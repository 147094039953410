import React from "react"
import styled from "styled-components"

const IncompleteStepsContent = styled.div`
  width: 240px;

  .step-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;

    .incomplete-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.palette.outrageousOrange};
      font-size: 9px;
      line-height: 18px;

      img {
        width: 12px;
        height: 12px;
        margin-bottom: 1px;
        margin-left: 4px;
      }
    }
  }

  p.help-text {
    font-size: 11px;
    margin: 0;
  }

  .outstanding-info-list {
    font-size: 11px;
    line-height: 14px;
    color: ${({ theme }) => `${theme.palette.white}80`};
    list-style: inside;
    margin: 0;

    li {
      margin: 0;
    }
  }

  .line-break {
    background-color: ${({ theme }) => `${theme.palette.white}50`};
    margin: 5px 0px;
  }
`

interface Props {
  incompleteSteps: {
    title: string
    helpText?: string
    outstandingInfo: string[]
  }[]
}

const IncompleteStepsToolTip: React.FC<Props> = ({ incompleteSteps }) => {
  return (
    <IncompleteStepsContent>
      {incompleteSteps.map((step, index) => (
        <div key={step.title}>
          <div className="step-header">
            <p>{step.title}</p>
            <div className="incomplete-indicator">
              <p>Incomplete</p>
              <img src="/images/icons/icon-dot.svg" alt="Incomplete icon" />
            </div>
          </div>
          {step.helpText ? <p className="help-text">{step.helpText}</p> : null}
          <ul className="outstanding-info-list">
            {step.outstandingInfo.map((info) => (
              <li key={info}>{info}</li>
            ))}
          </ul>
          {index !== incompleteSteps.length - 1 ? (
            <hr className="line-break" />
          ) : null}
        </div>
      ))}
    </IncompleteStepsContent>
  )
}

export default IncompleteStepsToolTip
