import React, { useRef, useState } from "react"
import styled from "styled-components"

import { ToolTip } from "../../components"
import { formatDateToString } from "../../utils/date"

const ImportantDatesWrapper = styled.div`
  grid-area: dates;
`

const DateDetails = styled.div`
  padding-right: 15px;
  justify-self: flex-end;
  display: flex;
  gap: 10px;
  font-size: 11px;
  padding-right: 10px;

  img {
    width: 14px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-self: flex-start;
    font-size: 14px;
    padding-right: 0px;
  }
`

const DatesToolTip = styled.div`
  width: 240px;

  .date-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;

    .row-label {
      font-weight: 600;
    }

    .completed-date {
      color: ${({ theme }) => `${theme.palette.white}80`};
      line-height: 18px;
      font-size: 11px;
    }

    .complete-indicator {
      font-size: 9px;
      line-height: 18px;
      color: ${({ theme }) => theme.palette.green};
    }

    .incomplete-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.palette.outrageousOrange};
      font-size: 9px;
      line-height: 18px;

      img {
        width: 12px;
        height: 12px;
        margin-bottom: 1px;
        margin-left: 4px;
      }
    }
  }

  .line-break {
    background-color: ${({ theme }) => `${theme.palette.white}50`};
    margin: 5px 0px;
  }
`

interface MerchantDate {
  label: string
  date: string
}

interface Props {
  dates: {
    signedUpOn: MerchantDate
    bwonOn: MerchantDate
    activatedOn: MerchantDate
  }
}

const ImportantDates: React.FC<Props> = ({ dates }) => {
  const dateDetailsIndicator = useRef<HTMLImageElement>(null)
  const [showToolTip, setShowToolTip] = useState(false)

  const datesArray = Object.values(dates)
  const latestDate = datesArray.reverse().find((date) => date.date !== null)

  return (
    <ImportantDatesWrapper
      id="dates-container"
      onMouseEnter={dates ? () => setShowToolTip(true) : () => null}
      onMouseLeave={dates ? () => setShowToolTip(false) : () => null}
    >
      <ToolTip
        open={showToolTip}
        referenceElement={dateDetailsIndicator}
        customOffsetX={50}
      >
        <DatesToolTip>
          {datesArray.map((date, index) => (
            <div key={date.label}>
              <div className="date-row">
                {date.date ? (
                  <>
                    <div>
                      <p className="row-label">{date.label}</p>
                      <p className="completed-date">
                        {formatDateToString(date.date)}
                      </p>
                    </div>
                    <div className="complete-indicator">
                      <p>Complete</p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="row-label">{date.label}</p>
                    <div className="incomplete-indicator">
                      <p>Incomplete</p>
                      <img
                        src="/images/icons/icon-dot.svg"
                        alt="Incomplete icon"
                      />
                    </div>
                  </>
                )}
              </div>
              {index !== datesArray.length - 1 ? (
                <hr className="line-break" />
              ) : null}
            </div>
          ))}
        </DatesToolTip>
      </ToolTip>
      <DateDetails>
        <img
          ref={dateDetailsIndicator}
          src="/images/icons/icon-complete.svg"
          alt="Completed icon"
        />
        <p>{formatDateToString(latestDate?.date || null)}</p>
      </DateDetails>
    </ImportantDatesWrapper>
  )
}

export default ImportantDates
