import React, { useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import { useGetMerchants, useUser } from "../../auth/hooks"
import {
  ContactIcons,
  Filter,
  PageNotificationBanner,
  Table as TableBase,
  PageHeader,
  PageGuard,
  TableButton,
} from "../../components"
import Seo from "../../components/seo"
import { TableHeading } from "../../components/Table"
import AssociatedResellerDetails from "../../merchant-status/components/AssociatedResellerDetails"
import ImportantDates from "../../merchant-status/components/ImportantDates"
import StatusChip, { Status } from "../../merchant-status/components/StatusChip"
import SupportTicketModal from "../../merchant-status/components/SupportTicketModal"

const Table = styled(TableBase)<{
  userIsPrincipal: boolean
}>`
  margin-top: 40px;

  .table-row {
    grid-template-areas:
      "merchant ${({ userIsPrincipal }) =>
        userIsPrincipal ? "reseller" : "placeholder"}"
      "status dates"
      "contact ticket";

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      grid-template-areas: "merchant ${({ userIsPrincipal }) =>
        userIsPrincipal && "reseller"} status dates ticket contact";
      margin-top: 0px;
    }
  }
`

const MerchantContainer = styled.div`
  grid-area: merchant;

  .business-name {
    font-weight: 500;
    line-height: 20px;
  }

  .owner-name {
    font-size: 12px;
    line-height: 22px;
  }
`

const ExtendedTableButton = styled(TableButton)`
  grid-area: ticket;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 10px;
  max-width: 160px;
  button {
    padding: 9px 15px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.lg}) {
    button {
      flex-direction: column;
    }
  }
`

interface FilterOption {
  title: string
  filterRef?: Reseller.MerchantStatusType
  quantity?: number
}

interface MerchantDate {
  label: string
  date: string
}
interface MerchantDates {
  signedUpOn: MerchantDate
  bwonOn: MerchantDate
  activatedOn: MerchantDate
}

interface MerchantDetails {
  ownerName: string
  businessName: string
}

interface AssociatedResellerDetails {
  fullName: string
  email: string
  referralCode: string
}
interface MerchantTableData {
  merchant: MerchantDetails
  associatedReseller: AssociatedResellerDetails | undefined
  product?: string
  quantity?: string
  status: {
    title: string
    color: string
    incompleteSteps?: {
      title: string
      outstandingInfo: string[]
    }[]
  }
  dates: MerchantDates
  contact: {
    firstName: string
    lastName: string
    email: string
    mobileNumber: string
  }
}

const renderMerchant = (data: MerchantDetails) => (
  <MerchantContainer key={`merchant-${data.ownerName}`}>
    <p className="business-name">{data.businessName}</p>
    <p className="owner-name">{data.ownerName}</p>
  </MerchantContainer>
)

const MerchantStatus = (): React.ReactElement => {
  const { user } = useUser()
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0)
  const [merchantsData, loading, refetch, fetchMore] = useGetMerchants()
  const [supportModalOpen, setSupportModalOpen] = useState(false)
  const [supportMerchantDetails, setSupportMerchantDetails] = useState<
    Reseller.Merchant | undefined
  >(undefined)

  const handleFilterChange = (index: number) => {
    refetch({
      status: filterOptions[index].filterRef,
    })
    setCurrentFilterIndex(index)
  }

  const handleSearchTerm = (searchTerm: string | undefined) => {
    refetch({
      status: filterOptions[currentFilterIndex].filterRef,
      username: searchTerm,
    })
  }

  const handleFetchMoreMerchants = useCallback(() => {
    fetchMore({
      variables: {
        after: merchantsData?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, merchantsData?.pageInfo.endCursor])

  const filterOptions: FilterOption[] = [
    {
      title: "All Merchants",
      filterRef: undefined,
      quantity: merchantsData?.count?.totalCount,
    },
    {
      title: "Newly Registered Merchants",
      filterRef: "SIGNED_UP",
      quantity: merchantsData?.count?.signedUpCount,
    },
    {
      title: "Fully Onboarded Merchants",
      filterRef: "FULLY_ONBOARDED",
      quantity: merchantsData?.count?.fullyOnboardedCount,
    },
    {
      title: "Active Merchants",
      filterRef: "ACTIVATED",
      quantity: merchantsData?.count?.activeCount,
    },
  ]

  const tableHeadings: TableHeading[] = useMemo(() => {
    const tempHeadingArray: TableHeading[] = [
      {
        name: "Merchant",
        accessor: "merchant",
        columnAllowance: "1.5fr",
        renderData: (index: number, data: MerchantDetails) =>
          renderMerchant(data),
      },
      {
        name: "Status",
        accessor: "status",
        columnAllowance: "1fr",
        renderData: (index: number, status: Status) => (
          <StatusChip key={`status-chip-${index}`} status={status} />
        ),
      },
      {
        name: "Impt. Date",
        accessor: "dates",
        columnAllowance: "1fr",
        renderData: (index: number, datesData: MerchantDates) => {
          return <ImportantDates key={`dates-${index}`} dates={datesData} />
        },
      },
      {
        name: "Support Ticket",
        accessor: "contact",
        columnAllowance: "1fr",
        renderData: (index: number, data: any) => (
          <ExtendedTableButton
            key={`support-ticket-${index}`}
            label="Make ticket"
            color="rgba(112,153,144, 0.7)"
            textColor="#222"
            borderColor="transparent"
            iconPath="/images/icons/icon-ticket.svg#base"
            onClick={() => {
              setSupportMerchantDetails(data)
              setSupportModalOpen(true)
            }}
          />
        ),
      },
      {
        name: "Make contact",
        accessor: "contact",
        columnAllowance: "minmax(30px, max-content)",
        renderData: (index: number, data: any) => (
          <ContactIcons key={`contacts-${index}`} data={data} index={index} />
        ),
      },
    ]

    if (user?.profile?.profileType == "PRINCIPAL") {
      tempHeadingArray.splice(1, 0, {
        name: "Reseller",
        accessor: "associatedReseller",
        columnAllowance: "1fr",
        renderData: (index: number, data: AssociatedResellerDetails) => (
          <AssociatedResellerDetails
            key={`reseller-${index}`}
            associatedReseller={data}
          />
        ),
      })
    }

    return tempHeadingArray
  }, [user?.profile?.profileType])

  const tableData: MerchantTableData[] = useMemo(() => {
    const tempTableData: MerchantTableData[] = []
    merchantsData?.edges.map((merchant) => {
      const tableItem = {
        merchant: {
          ownerName: merchant.node.firstName + " " + merchant.node.lastName,
          businessName: merchant.node.business?.tradingName,
        },
        associatedReseller:
          user?.profile?.profileType == "PRINCIPAL"
            ? merchant.node.associatedReseller
            : undefined,
        status: {
          title:
            merchant.node.status === "FULLY_ONBOARDED"
              ? "Fully Onboarded"
              : merchant.node.status === "SIGNED_UP"
              ? "Signed Up"
              : merchant.node.status === "ACTIVATED"
              ? "Active"
              : "",
          color:
            merchant.node.status === "FULLY_ONBOARDED"
              ? "014737"
              : merchant.node.status === "SIGNED_UP"
              ? "FA5D37"
              : merchant.node.status === "ACTIVATED"
              ? "00A9E0"
              : "FFF",
          incompleteSteps: merchant.node.incompleteSteps,
        },
        dates: {
          signedUpOn: {
            label: "Signed up on",
            date: merchant.node.createdAt,
          },
          bwonOn: {
            label: "Yoco approved on",
            date: merchant.node.bwonOn,
          },
          activatedOn: {
            label: "Activated on",
            date: merchant.node.activatedOn,
          },
        },
        contact: {
          firstName: merchant.node.firstName,
          lastName: merchant.node.lastName,
          email: merchant.node.email,
          mobileNumber: merchant.node.mobileNumber,
        },
      }
      tempTableData.push(tableItem)
    })
    return tempTableData
  }, [merchantsData, user?.profile?.profileType])

  return (
    <PageGuard verifyUserProfile>
      <Seo title="Merchant Status" />
      <PageHeader
        cypressTestId="merchant-status-page-header"
        title="Merchant status"
        hasSearch
        handleSearchTerm={handleSearchTerm}
      />
      <PageNotificationBanner>
        View and contact your connected merchants.
      </PageNotificationBanner>
      <Filter
        isPageFilter
        filterOptions={filterOptions}
        onClick={handleFilterChange}
        activeIndex={currentFilterIndex}
      />
      <Table
        userIsPrincipal={user?.profile?.profileType == "PRINCIPAL"}
        headings={tableHeadings}
        rowData={tableData}
        loading={loading}
        emptyTableText="You have not connected with any merchants yet"
        allRowsLoaded={!merchantsData?.pageInfo.hasNextPage}
        handleLoadMoreRows={handleFetchMoreMerchants}
      />
      <SupportTicketModal
        open={supportModalOpen}
        merchantDetails={supportMerchantDetails}
        onClose={() => setSupportModalOpen(false)}
      />
    </PageGuard>
  )
}

export default MerchantStatus
