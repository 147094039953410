import React, { useRef, useState } from "react"
import styled from "styled-components"

import { ToolTip, Chip } from "../../components"
import IncompleteStepsToolTip from "./IncompleteStepsToolTip"

export interface Status {
  title: string
  color: string
  incompleteSteps: {
    title: string
    outstandingInfo: string[]
  }[]
}

interface RenderChipProps {
  status: Status
}

const StatusChip = ({ status }: RenderChipProps): React.ReactElement => {
  const merchantChip = useRef<HTMLDivElement>(null)
  const [showToolTip, setShowToolTip] = useState(false)

  const showIncompleteSteps = status.incompleteSteps?.length

  return (
    <StatusChipContainer
      id="chipContainer"
      onMouseEnter={
        status.incompleteSteps ? () => setShowToolTip(true) : () => null
      }
      onMouseLeave={
        status.incompleteSteps ? () => setShowToolTip(false) : () => null
      }
    >
      {showIncompleteSteps ? (
        <ToolTip open={showToolTip} referenceElement={merchantChip}>
          <IncompleteStepsToolTip incompleteSteps={status.incompleteSteps} />
        </ToolTip>
      ) : null}
      <div ref={merchantChip}>
        <Chip
          text={`${status.title} ${
            showIncompleteSteps
              ? `${4 - status.incompleteSteps?.length ?? 0}/4`
              : ""
          }`}
          backgroundColor="inherit"
          borderColor={`#${status.color}40`}
          borderWidth="2px"
          textColor={`#${status.color}`}
          size="large"
          fontWeight="500"
          iconPath={
            showIncompleteSteps
              ? "/images/icons/icon-incomplete.svg"
              : undefined
          }
        />
      </div>
    </StatusChipContainer>
  )
}

export default StatusChip

const StatusChipContainer = styled.div`
  grid-area: status;
  display: flex;
`
