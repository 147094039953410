import React from "react"
import styled from "styled-components"

const Container = styled.div`
  grid-area: reseller;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  line-height: 20px;
  max-width: 120px;

  .details-wrapper {
    width: 100%;
    .primary-details {
      opacity: 1;
      position: absolute;
      height: 100%;
      p {
        display: flex;
        align-items: center;
        height: 100%;
        vertical-align: middle;
      }
    }

    .secondary-details {
      opacity: 0;
      p.email {
        font-weight: 500;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:hover {
    .primary-details {
      opacity: 0;
    }
    .secondary-details {
      opacity: 1;
      transition-delay: 0.1s;
    }
  }
`

interface Props {
  associatedReseller: {
    fullName: string
    email: string
    referralCode: string
  }
}

const AssociatedResellerDetails = ({
  associatedReseller,
}: Props): React.ReactElement => {
  return (
    <Container>
      <div className="details-wrapper">
        <div className="primary-details">
          <p>{associatedReseller.fullName}</p>
        </div>
        <div className="secondary-details">
          <p className="email">{associatedReseller.email}</p>
          <p>{associatedReseller.referralCode}</p>
        </div>
      </div>
    </Container>
  )
}

export default AssociatedResellerDetails
