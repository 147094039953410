import { Formik } from "formik"
import React, { useRef } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { useNotify } from "../../alert/hooks"
import { useUser } from "../../auth/hooks"
import {
  Modal,
  Input,
  DropDownInput,
  Button,
  FormGrid,
  TextArea,
} from "../../components"

const Container = styled.div`
  .title {
    font-size: 20px;
    margin-bottom: 25px;
    margin-right: 40px;
  }

  .full-width-input {
    grid-column: span 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 650px;
    .title {
      margin-right: 0px;
    }

    .full-width-input {
      grid-column: span 2;
    }
  }
`

const CompactFormGrid = styled(FormGrid)`
  grid-row-gap: 10px;
`

const ResellerCodeId = process.env.GATSBY_SUPPORT_TICKET_RESELLER_CODE_ID || ""

const validationSchema = Yup.object({
  name: Yup.string(),
  [ResellerCodeId]: Yup.string(),
  email: Yup.string(),
  phone: Yup.string(),
  subject: Yup.string().required(
    "Please select support area for this ticket's subject"
  ),
  description: Yup.string().required("Please write a description of the issue"),
})

const SupportCategories = [
  {
    label: "Tech",
    value: "TECH" as Reseller.SupportTicketAreaType,
  },
  {
    label: "Hardware",
    value: "HARDWARE" as Reseller.SupportTicketAreaType,
  },
  {
    label: "Payment",
    value: "PAYMENT" as Reseller.SupportTicketAreaType,
  },
  {
    label: "General",
    value: "GENERAL" as Reseller.SupportTicketAreaType,
  },
]

interface Props {
  open: boolean
  onClose: () => void
  merchantDetails?: Reseller.Merchant
}

const SupportTicketModal: React.FC<Props> = ({
  open,
  onClose,
  merchantDetails,
}: Props) => {
  const notify = useNotify()
  const { user } = useUser()
  const form = useRef<HTMLFormElement>(null)
  const initialValues = {
    orgid: process.env.GATSBY_SUPPORT_TICKET_ORGID_VALUE,
    retURL: `${process.env.GATSBY_SUPPORT_TICKET_RETURN_URL}?merchant_id=${merchantDetails?.firstName}`,
    debug: process.env.GATSBY_SUPPORT_TICKET_DEBUG_MODE === "true" ? 1 : 0,
    debugEmail:
      process.env.GATSBY_SUPPORT_TICKET_DEBUG_MODE === "true"
        ? "grant+testweb2case@yoco.com"
        : "",
    name: merchantDetails
      ? `${merchantDetails.firstName} ${merchantDetails.lastName}`
      : "",
    [ResellerCodeId]: user?.profile?.referralCode,
    email: merchantDetails ? merchantDetails.email : "",
    phone: merchantDetails ? merchantDetails.mobileNumber : "",
    subject: "",
    description: "",
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <h2 className="title">
          Log a support ticket for {merchantDetails?.firstName || "merchant"}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              form?.current?.submit()
              notify(
                "success",
                "Success",
                `a ${values.subject.toLowerCase()} support ticket has been logged for ${
                  values.name
                }. Message will reflect under Support Tracking in approximately 15min`
              )
            } catch (err) {
              console.warn(err)
            }
          }}
          enableReinitialize
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form
              ref={form}
              style={{ marginBottom: "0" }}
              onSubmit={handleSubmit}
              action={process.env.GATSBY_SUPPORT_TICKET_ACTION_URL}
              method="POST"
            >
              <input type="hidden" name="orgid" value={values.orgid} />
              <input type="hidden" name="retURL" value={values.retURL} />
              <input type="hidden" name="origin" value="Reseller" id="origin" />
              <input type="hidden" name="debug" value={values.debug} />
              <input
                type="hidden"
                name="debugEmail"
                value={values.debugEmail}
              />
              <CompactFormGrid numRows={2}>
                <Input
                  placeholder="Merchant Name"
                  name="name"
                  readOnly
                  label="Merchant name"
                />
                <input
                  type="hidden"
                  id={ResellerCodeId}
                  placeholder="Your Referral Code *"
                  name={ResellerCodeId}
                  value={values[ResellerCodeId]}
                />
                <input
                  type="hidden"
                  placeholder="Merchant Email *"
                  name="email"
                  value={values.email}
                />
                <input
                  type="hidden"
                  placeholder="Merchant Phone Number *"
                  name="phone"
                  value={values.phone}
                />
                <DropDownInput
                  placeholder="Select support area"
                  name="subject"
                  label="Subject *"
                  options={SupportCategories}
                  loading={false}
                />
                <div className="full-width-input">
                  <TextArea
                    label="Description *"
                    name="description"
                    placeholder={`Describe the issue ${merchantDetails?.firstName} is having...`}
                    resizable={false}
                  />
                </div>
              </CompactFormGrid>
              <Button
                label="Submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
                color="#018567"
                textColor="white"
              />
            </form>
          )}
        </Formik>
      </Container>
    </Modal>
  )
}

export default SupportTicketModal
